import axios from 'axios';
import { SET_LOADING, SET_ERROR_ALERT_OPEN } from '../MainReducer';
import { SET_OPERATION_DATA } from './OperationReducer';
import { ShowConfirmAlert } from '../MainAction'

export const GetRemainOperation = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({type: SET_OPERATION_DATA, data: []})
            const url = `/api/Operation/getRemainOperation`;
            const prodId = getState().Scan.data.prodid;

            let result = await axios.get(url, {params: {
                dataAreaID: "vilm",
                prodId: prodId,
            }
            });
            if(result.data != null){
                dispatch({type: SET_OPERATION_DATA, data: result.data})
                dispatch({ type: SET_LOADING, loading: false });
                return result;
            } else {
                //dispatch({type: SET_ERROR_ALERT_OPEN, errorAlert: true, error: "Error" })
                //dispatch(alert("Error"))
            }
        } catch (err) {
            console.log(err);
            dispatch({type: SET_OPERATION_DATA, data: []})

        }
        dispatch({ type: SET_LOADING, loading: false });
        return false;
    };
    
};
export const PostTime = (returnString) => {
    return async (dispatch, getState) => {
        let success = false;
        try {
            dispatch({ type: SET_LOADING, loading: true });

            let userId = getState().Main.userId;
            const url = `/api/Operation/postTime`;
            let result = await axios.post(url,  {
                _dataAreaID: "vilm",
                _userId: userId,
                _returnString: returnString
            });
            if(result.data._Status === "true"){
                success = true;
            } else {
                dispatch({type: SET_ERROR_ALERT_OPEN, errorAlert: true, error: result.data._Message })
            }
        } catch (err) {
            console.log(err);

        }
        dispatch(ShowConfirmAlert(false, "", ""));
        dispatch({ type: SET_LOADING, loading: false });
        return success;
    };
    
};
