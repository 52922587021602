import { CHANGE_STATE, SET_REPORT_DATA } from "./PeriodReducer";
import axios from 'axios';
import { SET_LOADING, SET_ERROR_ALERT_OPEN } from '../MainReducer';

export const ChangeState = (name, value) => {
    return async (dispatch, getState) => {
        dispatch({ type: CHANGE_STATE, name, value });
    };
};

export const GetReport = (grouping, clickedDate = null) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SET_LOADING, loading: true });
            const url = `/api/Report/getReport`;
            let startDate, lastDate;
            if(clickedDate){
                startDate = new Date(clickedDate);
                startDate = startDate.getFullYear()+"-"+(startDate.getMonth()+1)+"-"+startDate.getDate();
                lastDate = new Date(clickedDate);
                lastDate.setDate(lastDate.getDate()+1);
                lastDate = lastDate.getFullYear()+"-"+(lastDate.getMonth()+1)+"-"+lastDate.getDate();
            } else {
                var date = new Date();
                let period = getState().Period;
                let startMonth = date.getMonth();
                let endMonth = date.getMonth();
                if (period.thisMonth) {
                    endMonth = endMonth + 1;
                }
                if (period.lastMonth) {
                    startMonth = startMonth - 1;
                }
                if (period.thisAndlast) {
                    startMonth = startMonth - 1;
                    endMonth = endMonth + 1;
                }
                startDate = new Date(date.getFullYear(), startMonth, 1, 0);
                lastDate = new Date(date.getFullYear(), endMonth, 1, 0);

                startDate = startDate.getFullYear()+"-"+(startDate.getMonth()+1)+"-"+startDate.getDate();
                lastDate = lastDate.getFullYear()+"-"+(lastDate.getMonth()+1)+"-"+lastDate.getDate();
                
            }
            let result = await axios.get(url, {
                params: {
                    _dataAreaID: "vilm",
                    _startDate: startDate,
                    _endDate: lastDate,
                    _grouping: grouping
                },
            });
            if (result.data !== null && result.data !== []) {
                let nameOfData = grouping ? "groupedData" : "periodData";
                dispatch({ type: SET_REPORT_DATA, name: nameOfData, data: result.data.data })
                dispatch({ type: SET_LOADING, loading: false });
            }
        } catch (err) {
            dispatch({ type: SET_ERROR_ALERT_OPEN, errorAlert: true, error: err })
            //console.log(err);
        }

        dispatch({ type: SET_LOADING, loading: false });
        return false;
    };

};