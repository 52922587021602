import React, { Component } from 'react';
import { Typography, TextField, Grid, Button, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { LogIn, LoginToken } from './MainAction';
import { fieldStyle, buttonStyle } from '../utils/styles';
import { withRouter } from '../utils/withRouter';
import logo from '../images/logo.png'

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: "",
            pass: "",
        };
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
      }
     
      onClickHandler = () => {
        if(this.state.user !== "" && this.state.pass !== ""){
          this.props.LogIn(this.state.user, this.state.pass);
        } else {
          alert("Įveskite vartotojo vardą ir slaptažodį.")
        }
      }
      
    render() {
        let loading = this.props.main.loading;
        return (
             loading ?
             <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minHeight: '100vh' }}> 
             <CircularProgress color="inherit" /> </div> 
             : 
            <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent:"center", }}>
             <Typography variant='h5' sx={{
                textAlign: 'center',
                width: '100%',
                position: 'fixed',
                verticalAlign: 'bottom',
                top:1,
            }}>
                <img style={{marginTop: "15px", height: "21px"}} src={logo} alt="logo"/>
                </Typography>
                <Grid container
                direction='column'
                    justifyContent="center"
                    alignItems="center"
                    flex="1">
                        <TextField variant="outlined" label={'Vartotojo vardas'}
                         size="small" margin="dense" sx={fieldStyle} required
                         name="user" onChange={this.handleChange}/>
                        <TextField  variant="outlined" label={'Slaptažodis'}
                         size="small" margin="dense" sx={fieldStyle} required
                         name="pass" onChange={this.handleChange}
                         type="password"
                         onKeyPress={(ev) => {
                          if (ev.key === 'Enter') {
                            this.onClickHandler();
                            ev.preventDefault();
                          }
                        }}/>
                        <Button  size="medium" variant="outlined" sx={buttonStyle} onClick={this.onClickHandler} >Log in</Button>
                </Grid> 
              </div>
            
        );
    }
}

function mapStateToProps(state) {
    return {
      main: state.Main
    };
  }
   
  export default withRouter(connect(mapStateToProps, { LogIn, LoginToken })(Login));