import React, { Component } from 'react';
import { Typography, Grid, Button, Box, TextField, CircularProgress, Autocomplete } from '@mui/material';
import { withRouter } from '../../utils/withRouter'
import { connect } from 'react-redux';
import QrReader from 'modern-react-qr-reader';
import { GetProdInfo, GetProductionStatus } from './ScanAction';
import { GetRemainOperation } from '../OperationPage/OperationAction';
import { ShowConfirmAlert, ShowErrorAlert } from '../MainAction';
import Header from '../Layout/Header';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import ClearIcon from '@mui/icons-material/Clear';
class Scan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            delay: 300,
            operation: null,
            result: '',
            inputResult: '',
            isLoading: false,
            color: "#000000",
        }
        this.handleScan = this.handleScan.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handlePermissionError = this.handlePermissionError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
    }

    handleScan(data) {
        if (this.state.isLoading || this.props.main.errorAlert) {
            return;
        }
        if (data !== null && data !== '') {
            if (data.length > 2 && data.substring(0, 2).toLowerCase() === "mi") {
                this.setState({
                    color: "#8fce00"
                })
                this.handleSubmit(data);
            } else {
                let operation = this.props.scan.operData.find(o => o.oprid.toLowerCase() === data.toLowerCase());
                if (operation) {
                    this.setState({
                        color: "#8fce00",
                        operation: operation
                    })
                } else {
                    this.props.ShowErrorAlert(true, `Operacija ${data} nerasta`);
                }
            }
        }
    }
    handleSubmit(prodId) {
        this.setState({ isLoading: true });
        if (prodId === null || prodId === '') {
            this.handleError();
            this.setState({ isLoading: false, color: "#000000" });
        } else {
            if (this.state.operation !== null && this.state.operation.oprid !== "") {
                this.CheckAndConfirmProd(prodId, this.state.operation.name);
            } else { this.checkProd(prodId); }
        }
    }
    handleKeyPress(ev) {
        if (ev.key === 'Enter') {
            this.handleSubmit(this.state.inputResult);
            ev.preventDefault();
        }
    }
    async checkProd(prodId) {
        let result = await this.props.GetProductionStatus(prodId);
        if (result) {
            let prodInfoResult = await this.props.GetProdInfo(prodId);
            if (prodInfoResult) {
                this.props.GetRemainOperation();
                this.props.navigate("/operation");
            }
        }
        this.setState({ isLoading: false, color: "#000000" });
    }

    async CheckAndConfirmProd(prodId, operName) {
        let result = await this.props.GetProductionStatus(prodId);
        if (result) {
            let prodInfoResult = await this.props.GetProdInfo(prodId);
            if (prodInfoResult) {
                let remainInfo = await this.props.GetRemainOperation();
                if (remainInfo && remainInfo.data !== null) {
                    let operation = remainInfo.data.find(o => o._OperationName.toLowerCase() === operName.toLowerCase());
                    if (operation) {
                        let index = operation._returnString.lastIndexOf('.');
                        let returnString = operation._returnString;
                        if (index > -1) {
                            returnString = returnString.slice(0, index) + "." + operation._TimeInSek;
                        }
                        this.props.ShowConfirmAlert(true, returnString, operation._OperationName)
                    }
                    else this.props.ShowErrorAlert(true, `Operacija ${operName} nerasta`);
                }
            }
        }
        this.setState({ isLoading: false, color: "#000000" });
    }


    handleError(err) {
        //console.error(err)
        this.props.ShowErrorAlert(true, "Įveskite arba skenuokite MI numerį")
    }
    handlePermissionError(err) {
    }


    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleClearClick() {
        this.setState({ operation: null });
    };

    render() {
        return (
            <>
                {this.props.main.isAuthenticated && <Header scanPage={true} />}

                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    flex="1">
                    <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} >
                        <Autocomplete
                            disableClearable
                            includeInputInList
                            options={this.props.scan.operData}
                            value={this.state.operation}
                            getOptionLabel={(o) => o === null || o === "" ? "" : o.name}
                            isOptionEqualToValue={(option, value) => (option.oprid + option.recid) === (value.oprid + value.recid)}
                            onChange={(event, newValue) => {
                                this.setState({ operation: newValue });
                            }}
                            size="small" margin="dense"
                            sx={{
                                width: 195,
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#000000", borderRadius: 0 },
                                },
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.oprid + option.recid}>
                                        {option.name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} label="Operacijos pavadinimas" />}
                        />
                        <Button size="small" variant="outlined"
                            disabled={this.state.isLoading}
                            sx={{
                                border: '1px solid #6C8EBF',
                                background: "#DAE8FC",
                                borderRadius: 0,
                                color: '#000000',
                                '&:hover': {
                                    backgroundColor: '#6C8EBF',
                                },
                                height: 40,
                                marginLeft: 1,
                                padding: 0,
                            }}
                            onClick={this.handleClearClick}
                        >
                            {this.state.isLoading && <CircularProgress size={25} />}
                            {!this.state.isLoading && <ClearIcon  />}
                        </Button>
                    </Grid>
                    <Grid item >
                        <TextField variant="outlined" label={'MI nr.'}
                            size="small" margin="dense" sx={{
                                width: 195,
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#000000", borderRadius: 0 },
                                },
                            }}
                            name="inputResult" onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress.bind(this)} />
                        <Button size="medium" variant="outlined"
                            disabled={this.state.isLoading}
                            sx={{
                                border: '1px solid #6C8EBF',
                                background: "#DAE8FC",
                                borderRadius: 0,
                                color: '#000000',
                                '&:hover': {
                                    backgroundColor: '#6C8EBF',
                                },
                                width: 15,
                                height: 40,
                                marginTop: 1,
                                marginLeft: 1,
                                fontSize: 15
                            }}
                            onClick={() => this.handleSubmit(this.state.inputResult)}>
                            {this.state.isLoading && <CircularProgress size={25} />}
                            {!this.state.isLoading && <SearchSharpIcon />}
                        </Button>
                    </Grid>

                    <Typography sx={{ fontWeight: 'bold', marginRight: 19 }} variant='h7' > Skenuoti MI nr </Typography>
                    <Box component="span" sx={{ p: 1, border: '3px dashed ' + this.state.color }}>
                        <QrReader
                            style={{ width: "245px", height: "245px", }}
                            delay={this.state.delay}
                            constraints={{ facingMode: 'environment' }}
                            onError={this.handlePermissionError}
                            onScan={this.handleScan}
                        />
                    </Box>
                </Grid>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        main: state.Main,
        scan: state.Scan,
        oper: state.Operation
    };
}

export default withRouter(connect(mapStateToProps, {
    GetProdInfo, GetProductionStatus, GetRemainOperation, ShowErrorAlert, ShowConfirmAlert
})(Scan));
