
import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { ShowErrorAlert } from './MainAction';
import { withRouter } from '../utils/withRouter';


class ErrorAlert extends Component {
    CloseErrorAlert() {
        this.props.ShowErrorAlert(false, "")
    }
    render() {
        const { error, errorAlert } = this.props.main;
        
        return (
            <div>
                <Dialog
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "400px",

                            },
                        },
                    }}
                    open={errorAlert}>

                    <DialogTitle >
                        Klaida
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ fontWeight: 'bold'}}>
                            {error}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button size="medium" variant="outlined"
                        sx={{
                            border: '1px solid #6C8EBF',
                            borderRadius: 0,
                            backgroundColor: "#DAE8FC",
                            color: '#000000',
                            '&:hover': {
                                backgroundColor: '#6C8EBF',
                            },
                            width: 125,
                            marginLeft: 18,
                            marginTop: 1,
                            textTransform: "unset !important",
                            fontSize: 15
                        }}  onClick={this.CloseErrorAlert.bind(this)}>
                            Gerai
                            </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return { 
         main: state.Main };
}

export default withRouter(connect( mapStateToProps, {ShowErrorAlert})(ErrorAlert));
