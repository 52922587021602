import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, } from 'react-router-dom';


import App from './App';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import configureStore, {history} from './components/configureStore';
import { PersistGate } from 'redux-persist/integration/react'


export const {configStore, persistor } = configureStore();


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


root.render(
  <Provider store={configStore}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter location={history.location} navigator={history} >
       <App />
  </BrowserRouter>
  </PersistGate>
  </Provider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
