import React, { Component } from 'react';
import {  Grid } from '@mui/material';
import { connect } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { GetReport } from '../PeriodPage/PeriodAction';
import { withRouter } from '../../utils/withRouter'
import moment from 'moment';
import Header from '../Layout/Header';
import { GridLocaleText } from '../../LocaleText'


class TableMonthly extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { field: '_date', flex: 1, minWidth: 30,  headerName: 'Mėnesis', type: 'date', align:'center', headerAlign: 'center',
                    valueFormatter: params => moment(params?.value).format("YYYY-MM-DD"),  },
                { field: '_quantity', flex: 1, minWidth: 30, headerName: 'Laikas',align:'center', headerAlign: 'center' },
            ]
        }
    }

    async handleRowClick(params) {
        if(params && params.row && params.row._date){
            await this.props.GetReport(false, params.row._date);
            this.props.navigate("../period/operation-table");
        }
    }

    render() {
        const rows = this.props.period.groupedData;
        return (
            < >
                {this.props.main.isAuthenticated && <Header scanPage={false} />}

                <Grid container
                    direction="column"
                    justifyContent="center"
                    flex="1"
                    sx={{
                        paddingLeft: '5px',
                        paddingRight: '5px'
                    }}>
                    <DataGrid
                        sx={{
                            
                            '& .MuiDataGrid-virtualScrollerRenderZone':{
                                color: 'blue',
                                textDecorationLine: 'underline ',
                            },
                            flex: '1',
                            "& .MuiDataGrid-cell": {
                              padding: '0 5px',
                            },
                        }}
                        
                        rows={rows}
                        onRowClick={this.handleRowClick.bind(this)}
                        columns={this.state.columns}
                        hideFooter
                        getRowId={(row) => row._id + row._quantity + row._prodId}
                        localeText={GridLocaleText}
                    />
                </Grid>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        main: state.Main,
        period: state.Period,
    };
}

export default withRouter(connect(mapStateToProps, { GetReport })(TableMonthly));