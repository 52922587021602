import React, { Component, useDebugValue } from 'react';
import { Grid, Typography, } from '@mui/material';
import { connect } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { withRouter } from '../../utils/withRouter';
import Header from '../Layout/Header';
import { cloneElement } from 'react';
import { GridLocaleText } from '../../LocaleText'


class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          field: '_date', flex: 1, headerName: 'Data',
          type: 'date', valueFormatter: params => moment(params?.value).format("YYYY-MM-DD")
        },
        { field: '_prodIdOperation', flex: 1, headerName: 'Gamyba',
        valueFormatter: ({value}) => value.split('\n'), 
        renderCell: ({value}) => {
          let values = value.split('\n')
          return (
              <span> {values[0]}
                 <br />
                 {values[1]}
              </span> );} },
        { field: '_quantity', flex: 1, headerName: 'Laikas', type: 'number', align:'center', headerAlign: 'center' },
      ]
    }
  }


  render() {
    const rows = this.props.period.periodData;
    return (
      <>
        {this.props.main.isAuthenticated && <Header scanPage={false} />}

        <Grid container
          direction="column"
          flex="1"
          sx={{
            paddingLeft: '5px',
            paddingRight: '5px'

          }}>
          <DataGrid
            sx={{
              flex: '1',
              "& .MuiDataGrid-cell": {
                padding: '0 5px',
              },

            }}
            rows={rows}
            columns={this.state.columns}
            getRowId={(row) => row._internalId}
            localeText={GridLocaleText}
            pageSize={100}
            rowsPerPageOptions={[100]}
            componentsProps={{
              filterPanel: { sx: { maxWidth: "100vw" } }
            }}
            hideFooterSelectedRowCount
            hideFooter = {rows.length > 100 ? false : true}
          />
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    main: state.Main,
    period: state.Period
  };
}

export default withRouter(connect(mapStateToProps, {})(Table));