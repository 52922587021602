import React, { Component } from "react";
import "./custom.css";
import Login from "./components/Login";
import Scan from "./components/ScanPage/Scan";
import Period from "./components/PeriodPage/Period";
import { connect } from "react-redux";
import { Routes, Route, } from "react-router-dom";
import { LoginToken } from "./components/MainAction";
import Layout from "./components/Layout/Layout";
import TableMonthly from "./components/TablePage/TableMonthly";
import Table from "./components/TablePage/Table";
import Operation from "./components/OperationPage/Operation";
import { withRouter } from "./utils/withRouter";



class App extends Component {
  componentDidMount() {
    this.props.LoginToken();
    this.props.navigate("/");
  }
  render() {
    const { isAuthenticated } = this.props.main;
    if (!isAuthenticated) {
      return <Login />;
    } else {
      return (
        <Layout>
          <Routes>
            <Route  path="/" element={<Scan />} />
            <Route  path="/period"  element={<Period />} />
            <Route  path="/period/monthly-table"  element={<TableMonthly />} />
            <Route  path="/period/operation-table"  element={<Table />} />
            <Route  path="/operation"  element={<Operation />}/>
          </Routes>
        </Layout>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    main: state.Main,
  };
}

export default withRouter(connect(mapStateToProps, { LoginToken })(App));
