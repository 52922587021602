import React, { Component } from 'react';
import { Typography} from '@mui/material';
import { connect } from 'react-redux';
import { withRouter } from '../../utils/withRouter';


class Footer extends Component {
  
    render(){
        const {userId, userName} = this.props.main;
        return (
            <div style={{ marginBottom: '5px'}}>
                  <Typography sx={{
                    width: '100%',
                    fontWeight: 'bold',
                    marginLeft: 2
                }}
                    variant='h7' >{userId} | {userName} </Typography>
            </div>
           
        )
    }
}
function mapStateToProps(state) {
    return {
        main: state.Main,
    };
  }
   
export default withRouter(connect(mapStateToProps, {  })(Footer));