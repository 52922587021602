import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';

export const GridLocaleText = {
  // Root
  noRowsLabel: 'Nėra duomenų',
  noResultsOverlayLabel: 'Nerasta duomenų',
  errorOverlayDefaultLabel: 'Klaida',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Rasti stulpelį',
  columnsPanelTextFieldPlaceholder: 'Stulpelio pavadinimas',
  columnsPanelShowAllButton: 'Rodyti visus',
  columnsPanelHideAllButton: 'Slėpti visus',

  // Filter panel text
  filterPanelAddFilter: 'Pridėti filtrą',
  filterPanelDeleteIconLabel: 'Ištrinti',
  filterPanelLinkOperator: 'Loginis operatorius',
  filterPanelOperators: 'Loginis operatorius', // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: 'And',
  filterPanelOperatorOr: 'Or',
  filterPanelColumns: 'Stulpeliai',
  filterPanelInputLabel: 'Reikšmė',
  filterPanelInputPlaceholder: 'Filtro reikšmė',

  // Filter operators text
  filterOperatorContains: 'turi',
  filterOperatorEquals: 'yra',
  filterOperatorStartsWith: 'prasideda',
  filterOperatorEndsWith: 'baigiasi',
  filterOperatorIsEmpty: 'tuščias',
  filterOperatorIsNotEmpty: 'nėra tuščias',
  filterOperatorIsAnyOf: 'yra bet kuris',

  // Filter values text
  filterValueAny: 'any',
  filterValueTrue: 'true',
  filterValueFalse: 'false',

  // Column menu text
  columnMenuLabel: 'Meniu',
  columnMenuUnsort: "Nuimti rikiavimą",
  columnMenuSortAsc: <div><ArrowUpwardIcon />&nbsp;Rikiuoti didėjančia tvarka</div>,
  columnMenuSortDesc: <div><ArrowDownwardIcon />&nbsp;Rikiuoti mažėjančia tvarka</div>,
  columnMenuShowColumns: <div><WysiwygIcon />&nbsp;Stulpeliai</div>,
  columnMenuFilter: "Filtruoti",
  columnMenuHideColumn: "Slėpti",
};