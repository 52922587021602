import { LOG_OUT } from '../MainReducer';

export const CHANGE_STATE ="CHANGE_STATE";
export const SET_REPORT_DATA = "SET_REPORT_DATA";

const initialState = {
   thisMonth: true,
   lastMonth: false,
   thisAndlast: false,
   periodData: [],
   groupedData: []
};
const stateForAllCheckbox = {
    thisMonth: false,
    lastMonth: false,
    thisAndlast: false,
    periodData: [],
    groupedData: []



}
export const PeriodReducer = (state, action) => {
    state = state || initialState;
    
    switch (action.type) {
            case CHANGE_STATE:
            return {
                ...stateForAllCheckbox,
                [action.name]: action.value
            };
            case SET_REPORT_DATA:
                return {
                    ...state,
                    [action.name]: action.data
                };
              
                case LOG_OUT:
                    state = initialState;
                    break;
 
        default:
            break;
    };
    return state;
};
 
export default PeriodReducer;