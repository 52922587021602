
import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { ShowConfirmAlert } from './MainAction';
import { GetRemainOperation, PostTime } from './OperationPage/OperationAction';
import { withRouter } from '../utils/withRouter';


class ConfirmAlert extends Component {
    CloseAlert() {
        this.props.ShowConfirmAlert(false, "", "")
    }
    async HandleSubmit() {
        let result = await this.props.PostTime(this.props.main.returnString);
        if(result){
            let remainInfo = await this.props.GetRemainOperation();
            if(remainInfo.data === null || remainInfo.data.length === 0){
                this.props.navigate("/");
            }
        this.props.ShowConfirmAlert(false, "", "")

        }
    }
    render() {
        const {  operationName, confirmAlert, loading } = this.props.main;

        return (
            <div>
                <Dialog
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "350px",
                            },
                        },
                    }}
                    open={confirmAlert}>

                    <DialogContent>
                        <DialogContentText sx={{ fontWeight: 'bold' }}>
                            Ar tikrai norite registruoti operaciją: {operationName} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        <Button size="medium" variant="outlined"
                            disabled={loading}
                            color = "error"
                            sx={{
                                borderRadius: 0,
                                width: 125,
                                marginLeft: 18,
                                marginTop: 1,
                                textTransform: "unset !important",
                                fontSize: 15
                            }} onClick={this.CloseAlert.bind(this)}>
                            {loading && <CircularProgress size={25} />}
                            {!loading && 'Ne'}

                        </Button>
                        <Button size="medium" variant="outlined"
                            color = "success"
                            disabled={loading}
                            sx={{
                                borderRadius: 0,
                                //color: 'success',
                                width: 125,
                                marginLeft: 18,
                                marginTop: 1,
                                textTransform: "unset !important",
                                fontSize: 15
                            }} onClick={this.HandleSubmit.bind(this)}>
                            {loading && <CircularProgress size={25} />}
                            {!loading && 'Taip'}
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        main: state.Main,
        scan: state.Scan
    };
}

export default withRouter(connect(mapStateToProps, { ShowConfirmAlert, PostTime, GetRemainOperation })(ConfirmAlert));
