import React, { Component } from 'react';
import { Grid,  IconButton } from '@mui/material';
import { connect } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import { Logout } from '../MainAction';
import logo from '../../images/logo.png'
import { withRouter } from '../../utils/withRouter';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

class Header extends Component {

    handleLogOut() {
        this.props.Logout();
    }
    handleBackButton() {
        this.props.navigate(-1);
    }
    handleReportButton(){
        this.props.navigate("/period");

    }

    render() {
        return (
            <div>
                <Grid container spacing={0} sx={{
                    textAlign: 'center',
                    position: 'block',
                    top: 1,
                }}>
                    <Grid item xs={3}  textAlign='left'>
                        {!this.props.scanPage ? 
                        <IconButton color='inherit' onClick={this.handleBackButton.bind(this)}>
                            <ArrowCircleLeftRoundedIcon sx={{ fontSize: 40 }} />
                        </IconButton> : 
                    <IconButton color='inherit'  onClick={this.handleReportButton.bind(this)}>
                        <ArticleOutlinedIcon  sx={{ fontSize: 44 }} />
                    </IconButton> }
                    </Grid>
                    <Grid item xs={6}>
                        <img style={{ marginTop: "15px", height: "21px" }} src={logo} alt="logo" />
                    </Grid>
                    <Grid item xs={3} textAlign='right'>
                        <IconButton color='inherit' onClick={this.handleLogOut.bind(this)} >
                            <LogoutIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                    </Grid>
                </Grid>

            </div>
                

        )
    }
}
function mapStateToProps(state) {
    return {
        main: state.Main,

    };
}

export default withRouter(connect(mapStateToProps, { Logout })(Header));