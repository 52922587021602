import { LOG_OUT } from '../MainReducer';

export const SET_OPERATION_DATA = "SET_OPERATION_DATA";

const initialState = {
    operationData: []
 };
 
 export const OperationReducer = (state, action) => {
     state = state || initialState;
     
     switch (action.type) {
             case SET_OPERATION_DATA:
                 return {
                     ...state,
                     operationData: action.data
                 }
        case LOG_OUT:
            state = initialState;
            break;
         default:
             break;
     };
     return state;
 };
  
 export default OperationReducer;