
export const fieldStyle = {
  width: 260,
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: "#000000", borderRadius: 0 },
  },

};
export const buttonStyle = {
  border: '1px solid #6C8EBF',
  background: '#DAE8FC',
  borderRadius: 0,
  color: '#000000',
  '&:hover': {
    backgroundColor: '#6C8EBF',
  },
  width: 125,
  marginLeft: 16.5,
  marginTop: 1,
  textTransform: "unset !important",
  fontSize: 15
};

export const periodBtnStyle = {
  border: '1px solid #6C8EBF',
  background: '#DAE8FC',
  borderRadius: 0,
  color: '#000000',
  '&:hover': {
    backgroundColor: '#6C8EBF',
  },
  width: 125,
  textTransform: "unset !important",
  fontSize: 15
};
export const checkbox = {
  fontSize: 40, color: "#000000"
}