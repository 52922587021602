import React, { Component } from 'react';
import { Typography, Grid, Button, Checkbox, FormControlLabel } from '@mui/material';
import { connect } from 'react-redux';
import { checkbox, periodBtnStyle } from '../../utils/styles';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import { ChangeState, GetReport } from './PeriodAction';
import { withRouter } from '../../utils/withRouter';
import Header from '../Layout/Header';

class Period extends Component {

    handleChange(event, data) {
        this.props.ChangeState(event.target.name, event.target.checked)
    }
    handleMonthlyClick() {
        this.props.GetReport(true);
        this.props.navigate("/period/monthly-table");
    }
    handleClick() {
        this.props.GetReport(false);
        this.props.navigate("/period/operation-table");
    }
    handleBackButton() {
        this.props.navigate(-1);
    }

    render() {
        let { thisMonth, lastMonth, thisAndlast } = this.props.period;
        return (
            <>
                {this.props.main.isAuthenticated && <Header scanPage={false} />}

                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="left"
                    flex="1"
                    textAlign='center'
                >
                    <Typography sx={{ fontWeight: 'bold' }} variant='h7' > Pasirinkite laikotarpį </Typography>
                    <FormControlLabel
                        sx={{ marginLeft: 5 }}
                        control={
                            <Checkbox
                                name="thisMonth"
                                checked={thisMonth}
                                onChange={this.handleChange.bind(this)}
                                icon={<RadioButtonUncheckedRoundedIcon sx={checkbox} />}
                                checkedIcon={<RadioButtonCheckedRoundedIcon sx={checkbox} />} />   }
                        label={<Typography variant="h5" color="#000000">Šis mėnuo</Typography>} />
                    <FormControlLabel
                        sx={{ marginLeft: 5 }}
                        control={
                            <Checkbox
                                name="lastMonth"
                                checked={lastMonth}
                                onChange={this.handleChange.bind(this)}

                                icon={<RadioButtonUncheckedRoundedIcon sx={checkbox}/>}
                                checkedIcon={<RadioButtonCheckedRoundedIcon sx={checkbox} />} /> }
                        label={<Typography variant="h5" color="#000000">Praėjęs mėnuo</Typography>} />
                    <FormControlLabel
                        sx={{ marginLeft: 5 }}
                        control={
                            <Checkbox
                                name="thisAndlast"
                                checked={thisAndlast}
                                onChange={this.handleChange.bind(this)}
                                icon={<RadioButtonUncheckedRoundedIcon sx={checkbox} />}
                                checkedIcon={<RadioButtonCheckedRoundedIcon sx={checkbox} />} /> }
                        label={<Typography variant="h5" color="#000000">Šis ir Praėjęs mėnuo</Typography>} />
                    <Grid container >
                        <Grid item xs={5.75} textAlign= 'right' >
                            <Button size="medium" sx={periodBtnStyle}  onClick={this.handleMonthlyClick.bind(this)} >Suvestinė</Button>
                        </Grid>
                        <Grid item xs={0.5} ></Grid>
                        <Grid item xs={5.75} textAlign= 'left'>
                            <Button size="medium" sx={periodBtnStyle} onClick={this.handleClick.bind(this)} >Sąrašas</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        main: state.Main,
        period: state.Period
    };
}

export default withRouter(connect(mapStateToProps, { ChangeState, GetReport })(Period));