import axios from 'axios';
import { authHeader } from '../utils/AuthHeader';
import { SET_AUTHENTIFICATION, LOG_OUT, LOGIN, SET_MOUNTED, SET_LOADING, SET_ERROR_ALERT_OPEN, SET_CONFIRM_ALERT_OPEN } from './MainReducer';
import { GetOperations } from './ScanPage/ScanAction';



export const LogIn = (user, pass) => {
    return async (dispatch, getState) => {
        dispatch({ type: SET_LOADING, loading: true });
        try {
            const url = `/api/Login`;
            const result = await axios.post(url, {
                user: user,
                psw: pass,
                daid: "vilm"
            });
 
            dispatch(Authenticate(result));
 
            if (result.data.success === true && result.data.userId !== null) {
                dispatch({ type: SET_AUTHENTIFICATION, mode: true })
                dispatch(GetOperations());
            }
            else {
                dispatch({ type: SET_AUTHENTIFICATION, mode: false })
                dispatch(alert("Neteisingas vartotojo vardas arba slaptažodis!"))

            }
        } catch (err) {
            dispatch({ type: SET_LOADING, loading: false });

        }
        dispatch({ type: SET_LOADING, loading: false });

    };
};
 
export const Authenticate = (result) => {
    return async (dispatch, getState) => {
        if (result.data.success) {
            const user = result.data;
            if (user.token !== null && user.token !== "") {
                sessionStorage.user = JSON.stringify(user);
                sessionStorage.setItem('token', user.token);
                axios.defaults.headers.common = authHeader();
                dispatch({ type: LOGIN, userid: user.userId, username: user.name, isAuthenticated: true, loading: false,});
            } else {
                dispatch({ type: SET_LOADING, loading: false });
                dispatch({ type: SET_MOUNTED, mounted: true });
            }
        } else {
            let data = result.data;
            if(data.success === false){
                dispatch({ type: LOGIN, isAuthenticated: false, loading: false });
            }
            else {
            }
        }
        dispatch({ type: SET_MOUNTED, mounted: true });
    };
 
};
 
export const Logout = () => {
    return async (dispatch, getState) => {
        sessionStorage.clear();
        dispatch({ type: LOG_OUT });
    };
};
 
export const ShowErrorAlert = (errorAlert, error) => {
    return async (dispatch, getState) => {
        dispatch({ type: SET_ERROR_ALERT_OPEN, errorAlert: errorAlert, error: error });
    };
};
export const ShowConfirmAlert = (confirmAlert, returnString, operationName) => {
    return async (dispatch, getState) => {
        dispatch({ type: SET_CONFIRM_ALERT_OPEN, confirmAlert: confirmAlert, returnString: returnString, operationName: operationName});
    };
};

export const LoginToken = () => {
    return async (dispatch, getState) => {
        let token = sessionStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common = authHeader();
            dispatch(LoginActionToken());
        } else {
            dispatch({ type: SET_MOUNTED, mounted: true });
        }
    };
};
 
export const LoginActionToken = () => {
    return async (dispatch, getState) => {
        dispatch({ type: SET_LOADING, loading: true });
        try {
            const url = `/api/Login/loginToken`;
            const result = await axios.get(url);
            dispatch(Authenticate(result));
        } catch (err) {
            dispatch({ type: SET_LOADING, loading: false });
            dispatch({ type: SET_MOUNTED, mounted: true });
        }
    };
};