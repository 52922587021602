export const SET_AUTHENTIFICATION = "SET_AUTHENTIFICATION";
export const LOG_OUT = "LOG_OUT";
export const LOGIN = "LOGIN";
export const SET_MOUNTED = "SET_MOUNTED";
export const SET_LOADING ="SET_LOADING";
export const SET_ERROR_ALERT_OPEN = "SET_ERROR_ALERT_OPEN";
export const SET_CONFIRM_ALERT_OPEN = "SET_CONFIRM_ALERT_OPEN"
const initialState = {
    userId: "",
    userName: "",
    isAuthenticated: false,
    error: "",
    loading: false,
    mounted: false,
    errorAlert: false,
    confirmAlert: false,
    returnString: "",
    operationName: "",

};

export const MainReducer = (state, action) => {
    state = state || initialState;
 
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                userId: action.userid,
                userName: action.username,
                isAuthenticated: action.isAuthenticated,
                loading: action.loading,
            };
            case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
 
        case SET_AUTHENTIFICATION:
            state = {
                ...state,
                isAuthenticated: action.mode
            };
            break;
        case SET_MOUNTED:
            return {
                ...state,
                mounted: action.mounted
            };
            case SET_ERROR_ALERT_OPEN:
                return {
                    ...state,
                    errorAlert: action.errorAlert,
                    error: action.error
                };
                case SET_CONFIRM_ALERT_OPEN:
                    return {
                        ...state,
                        confirmAlert: action.confirmAlert,
                        returnString: action.returnString,
                        operationName: action.operationName
                    };
        case LOG_OUT:
            state = initialState;
            break;
          
        default:
            break;
    };
    return state;
};
 
export default MainReducer;