import { SET_ACCESS_OPERATION, SET_OPER_DATA, SET_PROD_DATA } from "./ScanReducer";
import axios from 'axios';
import { SET_ERROR_ALERT_OPEN, SET_LOADING } from "../MainReducer";

export const GetProductionStatus = (prodid) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SET_LOADING, loading: true });
            const url = `/api/Operation/getProduction`;
            let result = await axios.get(url, {params: {
                dataAreaID: "vilm",
                prodId: prodid,
            },});
            if(result.data._Status === "true"){
                dispatch({ type: SET_LOADING, loading: false });
                return true;
            } else {
                dispatch({type: SET_ERROR_ALERT_OPEN, errorAlert: true, error: result.data._Message })
            }
        } catch (err) {
            console.log(err);
            dispatch({type: SET_ACCESS_OPERATION, access: false })

        }
        dispatch({ type: SET_LOADING, loading: false });

        return false;
    };
    
};
export const GetProdInfo = (prodid) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SET_PROD_DATA, data: null});
            const url = `/api/Operation/getProdInfo`;
            let result = await axios.get(url, {params: {
                dataAreaID: "vilm",
                prodId: prodid,
            },});
            if(result.data.success === true){
                dispatch({ type: SET_PROD_DATA, data: result.data.data });
                dispatch({type: SET_ACCESS_OPERATION, access: true })
                dispatch({ type: SET_LOADING, loading: false });
                return true;
            } else {
                dispatch({type: SET_ACCESS_OPERATION, access: false })
                dispatch({ type: SET_PROD_DATA, data: null});
                dispatch({type: SET_ERROR_ALERT_OPEN, errorAlert: true, error: "Nepavyko gauti gamybos "+prodid+" informacijos." })


            }
        } catch (err) {
            console.log(err);
            dispatch({type: SET_ACCESS_OPERATION, access: false })

        }
        dispatch({ type: SET_LOADING, loading: false });
        return false;
    };
};

export const GetOperations = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: SET_OPER_DATA, operData: []});
            const url = `/api/Operation/getOperations`;
            let result = await axios.get(url, {params: {
                dataAreaID: "vilm",
            },});
            if(result.data.success === true){
                dispatch({ type: SET_OPER_DATA, operData: result.data.data});
                dispatch({ type: SET_LOADING, loading: false });
            }
        } catch (err) {
            console.log(err);
        }
        dispatch({ type: SET_LOADING, loading: false });
    };
};

export const OperationPageAccess = (mode) =>{
    return async (dispatch ) => {
        dispatch({ type: SET_LOADING, loading: false });
        dispatch({type: SET_ACCESS_OPERATION, access: mode })
    }
}