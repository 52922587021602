import React, { Component } from 'react';
import { Typography, Button,  Grid} from '@mui/material';
import { connect } from 'react-redux';
import { ShowConfirmAlert } from '../MainAction';
import { DataGrid } from '@mui/x-data-grid';
import { OperationPageAccess } from '../ScanPage/ScanAction';
import { withRouter } from '../../utils/withRouter';
import { PostTime } from './OperationAction';
import Header from '../Layout/Header';
import { GridLocaleText } from '../../LocaleText'


class Operation extends Component {
    constructor(props) {
        super(props);
        const me = this;
        this.state = {
            columns: [
                { field: '_OperationName', flex: 3, minWidth: 30},
                { field: '_TimeInSek', width: 65},
                { field: "Button", width: 70,
                    renderCell: (cellValues) => {
                        return (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(event) => {
                                    this.handleGoButtonClick(event, cellValues);
                                }}
                            >
                             GO
                            </Button> );} } ]
        }
    }

    handleClick() {
        this.props.OperationPageAccess(false);
    }
    handleGoButtonClick(event, cellValues){
        let index = cellValues.row._returnString.lastIndexOf('.');
        let returnString = cellValues.row._returnString;
        if(index > -1){
            returnString = returnString.slice(0, index)+"." + cellValues.row._TimeInSek;
        }
        this.props.ShowConfirmAlert(true, returnString, cellValues.row._OperationName)
    }

    render() {
        let rows = this.props.oper.operationData;
        let data = this.props.scan.data;
        return (
            <>
                {this.props.main.isAuthenticated && <Header scanPage={false} />}
                {this.props.scan.access ?
                        <Grid container
                            direction="column"
                            justifyContent="center"
                            flex="1"
                            sx={{
                                marginTop: '1vh',
                                paddingLeft: '5px',
                                paddingRight: '5px'
                            }}>
                            <Typography sx={{ fontWeight: 'bold' }} variant='h7' > {data.prodid}</Typography>
                            <Typography variant='h7' sx={{marginBottom: '5px'}}> {data.itemid} : {data.configid}</Typography>
                            <Typography variant='h7' sx={{marginBottom: '10px'}} > {data.name}</Typography>
                            <DataGrid
                                sx={{
                                    flex: '1',
                                    "& .MuiDataGrid-columnHeaders": {
                                        visibility: 'hidden',
                                      },
                                      "& .MuiDataGrid-cell": {
                                        padding: '0 5px',
                                      }, 
                                                              
                                }}
                                headerHeight={0}
                                rows={rows}
                                columns={this.state.columns}
                                onCellKeyDown={(params, events) => events.stopPropagation()}
                                disableColumnMenu
                                hideFooter
                                getRowId={(row) => row._rowId+row._TimeInSek+row._returnString}
                                localeText={GridLocaleText}
                            />
                        </Grid>
                    :
                    <>
                    </>}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        main: state.Main,
        scan: state.Scan,
        oper: state.Operation
    };
}

export default withRouter(connect(mapStateToProps, { OperationPageAccess,PostTime, ShowConfirmAlert })(Operation));