import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import MainReducer from './MainReducer';
import thunk from 'redux-thunk';
import PeriodReducer from './PeriodPage/PeriodReducer';
import ScanReducer from './ScanPage/ScanReducer';
import OperationReducer from './OperationPage/OperationReducer';
import storage from 'redux-persist/lib/storage/session'
import { persistStore, persistReducer } from 'redux-persist'
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from "history";

export const history = createBrowserHistory()
const enhancers = [];
const isDevelopment = process.env.NODE_ENV === 'development';
if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}
const persistConfig = {
    key: 'root',
    version: 13,
    storage,
    whitelist: ['Period', 'Scan', 'Operation'],
}

const reducers = {
    Main: MainReducer,
    Period: PeriodReducer,
    Scan: ScanReducer,
    Operation: OperationReducer
}
const middleware = [
    thunk,
    routerMiddleware(history)
  ]; 

const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
    let configStore = createStore(persistedReducer, compose(applyMiddleware(...middleware), ...enhancers))
    let persistor = persistStore(configStore)
    return { configStore, persistor }
  }
