
import React, { Component } from 'react';
import { connect } from "react-redux";
import Footer from './Footer';
import { withRouter } from '../../utils/withRouter';
import ErrorAlert from '../ErrorAlert';
import ConfirmAlert from '../ConfirmAlert';

 
class Layout extends Component {
 
  static Content = ({ children }) => 
    <div >
      {children}
    </div>;


  render () {
    return (
      <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent:"center", }}>
        <ErrorAlert />
        <ConfirmAlert />
        {this.props.children}
        {this.props.main.isAuthenticated && <Footer/>}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    main: state.Main
  };
}
 
export default withRouter(connect(
  mapStateToProps,
  {  }
)(Layout));
