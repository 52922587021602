import { LOG_OUT } from '../MainReducer';

export const SET_ACCESS_OPERATION ="SET_ACCESS_OPERATION";
export const SET_PROD_DATA = "SET_PROD_DATA";
export const SET_OPER_DATA = "SET_OPER_DATA";
const initialState = {
   access: false,
   data: null,
   operData: []
};

export const ScanReducer = (state, action) => {
    state = state || initialState;
    
    switch (action.type) {
            case SET_ACCESS_OPERATION:
            return {
                ...state,
               access: action.access
            }

            case SET_PROD_DATA:
                return {
                    ...state,
                    data: action.data
                }
            case SET_OPER_DATA:
                return {
                    ...state,
                    operData: action.operData
                }
                case LOG_OUT:
                    state = initialState;
                    break;
                
     
        default:
            break;
    };
    return state;
};
 
export default ScanReducer;